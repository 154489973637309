import React from "react";
import {
  Table, TableBody, TableRow, TableCell, TableContainer,
} from "@material-ui/core";
import { useStyles } from "../../helpers/project";
import PropTypes from "prop-types";

export default function DetailsBloc(props) {
  const classes = useStyles();
  const { client } = props;

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-label="Détails"
      >
        <TableBody>
          <TableRow key={"nom"}>
            <TableCell
              component="th"
              scope="row"
            >
              Nom
            </TableCell>
            <TableCell align="right">
              {client.name}
            </TableCell>
          </TableRow>

          <TableRow key={"prenom"}>
            <TableCell
              component="th"
              scope="row"
            >
              Prénom
            </TableCell>
            <TableCell align="right">
              {client.firstname}
            </TableCell>
          </TableRow>

          <TableRow key={"email"}>
            <TableCell
              component="th"
              scope="row"
            >
              Email
            </TableCell>
            <TableCell align="right">
              {client.email}
            </TableCell>
          </TableRow>

          <TableRow key={"telephone"}>
            <TableCell
              component="th"
              scope="row"
            >
              N° de téléphone
            </TableCell>
            <TableCell align="right">
              {client.phone}
            </TableCell>
          </TableRow>

          {client.company !==
          null && (
            <TableRow key={"societe"}>
              <TableCell
                component="th"
                scope="row"
              >
                Société
              </TableCell>
              <TableCell align="right">
                {client.company}
              </TableCell>
            </TableRow>
          )}

          {client.siret !== null && (
            <TableRow key={"siret"}>
              <TableCell
                component="th"
                scope="row"
              >
                SIRET
              </TableCell>
              <TableCell align="right">
                {client.siret}
              </TableCell>
            </TableRow>
          )}

          <TableRow key={"adresse"}>
            <TableCell
              component="th"
              scope="row"
            >
              Adresse
            </TableCell>
            <TableCell align="right">
              {client.address}
            </TableCell>
          </TableRow>

          <TableRow key={"ville"}>
            <TableCell
              component="th"
              scope="row"
            >
              Ville
            </TableCell>
            <TableCell align="right">
              {client.city}
            </TableCell>
          </TableRow>

          <TableRow key={"departement"}>
            <TableCell
              component="th"
              scope="row"
            >
              Département
            </TableCell>
            <TableCell align="right">
              {client.postCode}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DetailsBloc.propTypes = {
  client: PropTypes.object.isRequired,
};
